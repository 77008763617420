import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import "./css/style.scss";
import AOS from "aos";
//import { focusHandling } from "cruip-js-toolkit";

import Home from "./pages/Home";
import Reser4me from "./pages/Products/Reserv4me/Reserv4me";
import Kiosk from "./pages/Products/Kiosk/Kiosk";
import About from "./pages/About";
import Contact from "./pages/Contact";
import PageNotFound from "./pages/PageNotFound";
import Reser4meCRM from "./pages/Products/Reserv4meCRM/Reserv4meCRM";

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 600,
      easing: "ease-out-sine",
    });
  });

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    //focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/products/Reserv4me">
          <Reser4me />
        </Route>
        <Route exact path="/products/Reserv4meCRM">
          <Reser4meCRM />
        </Route>
        <Route exact path="/products/kiosk">
          <Kiosk />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
}

export default App;
