import React from "react";

function HeroFeatures() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        {/* Illustration behind hero content */}

        {/* Hero content */}
        <div className="relative pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Content */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 mb-4" data-aos="fade-up">
              Reserv4me CRM
            </h1>
            <p
              className="text-xl text-gray-400"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Reserv4me CRM is a means to keep track of all of your rentals,
              keep an eye on rates and demand using data gathered from any
              client that visits your page, one can set rates, block dates and
              more
            </p>
          </div>
          {/* Image */}
          <div className="composition lg:h-112 md:h-96 h-40 mx-auto pb-12 md:pb-16">
            <img
              className="composition__photo composition__photo--p1"
              src="https://res.cloudinary.com/dykrzfpex/image/upload/v1645904125/Screenshot_from_2022-02-26_14-13-21_ccyq9d.png"
              width="768"
              height="432"
              alt="Hero"
              data-aos="fade-up"
            />
            <img
              className="composition__photo composition__photo--p2"
              src="https://res.cloudinary.com/dykrzfpex/image/upload/v1645903735/Screenshot_from_2022-02-26_14-23-14_z1p8k1.png"
              width="768"
              height="432"
              alt="Hero"
              data-aos="fade-up"
            />
            <img
              className="composition__photo composition__photo--p3"
              src="https://res.cloudinary.com/dykrzfpex/image/upload/v1639422101/Screenshot_from_2021-12-13_13-57-13_sf9qn8.png"
              width="768"
              height="432"
              alt="Hero"
              data-aos="fade-up"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroFeatures;
