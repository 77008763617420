import React from "react";

function Timeline() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pt-32 pb-12 md:pb-20">
            <h2 className="h2 mb-4">Making ideas come true</h2>
            <p className="text-xl text-gray-400">
              The journey has been long but its not over yet
            </p>
          </div>

          {/* Items */}
          <div
            className="max-w-3xl mx-auto -my-4 md:-my-6"
            data-aos-id-timeline
          >
            {/* 1st item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                  The light bulb turned on
                </div>
                <div className="flex items-center mb-3 ">
                  <div className="absolute -left-2 md:-left-4 lg:-left-8 inline-flex text-xs md:text-sm font-semibold py-1 px-2 sm:px-3 text-green-600 bg-green-200 rounded-full ">
                    May,2017
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-blue-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4">
                    The idea to start a consulting business
                  </h4>
                </div>
                <p className="text-lg text-gray-400">
                  At first a consulting business came to mind after graduating
                  college
                </p>
              </div>
            </div>

            {/* 2nd item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                  Prototype / Research face
                </div>
                <div className="flex items-center mb-3">
                  <div className="absolute -left-2 md:-left-4 lg:-left-8 inline-flex text-xs md:text-sm font-semibold py-1 px-2 sm:px-3 text-green-600 bg-green-200 rounded-full ">
                    Sep,2017
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-blue-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4">
                    Researched the posibility of coding a custom software
                  </h4>
                </div>
                <p className="text-lg text-gray-400">
                  After working in the hospitality industry for a couple of
                  years and seeing the lack of software that could make the life
                  easy of those who manage properties,
                </p>
              </div>
            </div>

            {/* 3rd item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                  Putting plan into acion
                </div>
                <div className="flex items-center mb-3">
                  <div className="absolute -left-2 md:-left-4 lg:-left-8 inline-flex text-xs md:text-sm font-semibold py-1 px-2 sm:px-3 text-green-600 bg-green-200 rounded-full ">
                    Jun,2018
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-blue-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4">First Alpha completed</h4>
                </div>
                <p className="text-lg text-gray-400">
                  After coding for months, reading several books and web courses
                  a first iteration of the software was completed. The
                  infrastructure used was Access database and C# code base, the
                  coding was thedious and the database needed some improvements
                  to be launched online.
                </p>
              </div>
            </div>

            {/* 4th item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                  Realization
                </div>
                <div className="flex items-center mb-3">
                  <div className="absolute -left-2 md:-left-4 lg:-left-8 inline-flex text-xs md:text-sm font-semibold py-1 px-2 sm:px-3 text-green-600 bg-green-200 rounded-full ">
                    Nov,2018
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-blue-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4">
                    Our first iteration was good but lacked availability
                  </h4>
                </div>
                <p className="text-lg text-gray-400">
                  The lack of a nice framework such as ReactJS,Tailwindcss while
                  using C# environment also the lack of accessibility of the
                  database, we went back to the white board and researched how
                  to make improve it, Open source web teachnologies were the
                  solution to all of our problems. Spent time learning how to
                  code, take security precautions, and learning while doing was
                  all it took
                </p>
              </div>
            </div>
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                  Web technologies integration
                </div>
                <div className="flex items-center mb-3">
                  <div className="absolute -left-2 md:-left-4 lg:-left-8 inline-flex text-xs md:text-sm font-semibold py-1 px-2 sm:px-3 text-green-600 bg-green-200 rounded-full ">
                    Dec,2019
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-blue-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4">
                    ElectronJS,ReactJS among other technologies
                  </h4>
                </div>
                <p className="text-lg text-gray-400">
                  The once desktop app now was available on the web and on the
                  desktop available from almost anywhere yet since it was coded
                  with bootstrap a facelift was needed, some aspects still
                  needed work on but mostly was working. We obtained our first
                  client. The sky is the limit from now onwards
                </p>
              </div>
            </div>
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                  Fire test
                </div>
                <div className="flex items-center mb-3">
                  <div className="absolute -left-2 md:-left-4 lg:-left-8 inline-flex text-xs md:text-sm font-semibold py-1 px-2 sm:px-3 text-green-600 bg-green-200 rounded-full ">
                    Jun,2020
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-blue-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4">
                    Our software was going to be put to used in the wild
                  </h4>
                </div>
                <p className="text-lg text-gray-400">
                  Our software passed the fire test and has been working helping
                  properties manage reservations a breeze.
                </p>
              </div>
            </div>
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                  Work is ongoing.
                </div>
                <div className="flex items-center mb-3">
                  <div className="absolute -left-2 md:-left-4 lg:-left-8 inline-flex text-xs md:text-sm font-semibold py-1 px-2 sm:px-3 text-green-600 bg-green-200 rounded-full ">
                    Present
                  </div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-blue-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4">No software is ever finished</h4>
                </div>
                <p className="text-lg text-gray-400">
                  Any software needes to be maintain to withstand the tests of
                  time, from simple upgrades in looks to necessary security
                  upgrades, as well as adding new features to maintain
                  inovation. Work is never over once its launched.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Timeline;
