import React from "react";

function FeaturesHome() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Items */}
          <div className="grid gap-20" data-aos-id-features-home>
            {/* Item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
                data-aos="fade-up"
                data-aos-anchor="[data-aos-id-features-home]"
              >
                <div className="relative">
                  <img
                    className="md:max-w-none"
                    src="https://res.cloudinary.com/dykrzfpex/image/upload/v1639547135/Engsys-display_fs3afq.png"
                    width="540"
                    height="520"
                    alt="Features illustration"
                  />
                </div>
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-right"
                data-aos-anchor="[data-aos-id-features-home]"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  {/*<div className="font-architects-daughter text-xl text-blue-700 mb-2">
                    Modern Technologies to speed delivery
  </div>*/}
                  <h3 className="h3 mb-3">
                    Modern Technologies to speed delivery
                  </h3>
                  <p className="text-xl text-gray-400 mb-4">
                    We use modern Frameworks based on open source code to speed
                    the development of new ideas, technologies used by the big
                    playes in the industry
                  </p>
                  <ul className="flex flex-wrap text-lg text-gray-400 -mx-2 -my-1">
                    <li className="flex items-center mx-2 my-1">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>ReactJS</span>
                    </li>
                    <li className="flex items-center mx-2 my-1">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>NodeJS</span>
                    </li>
                    <li className="flex items-center mx-2 my-1">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>ExpressJS</span>
                    </li>
                    <li className="flex items-center mx-2 my-1">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>ElectronJS</span>
                    </li>
                    <li className="flex items-center mx-2 my-1">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Javascript</span>
                    </li>
                    <li className="flex items-center mx-2 my-1">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>HTML</span>
                    </li>
                    <li className="flex items-center mx-2 my-1">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>CSS/Bootstrap/TailwindCSS</span>
                    </li>
                  </ul>
                  <div className="flex items-start mt-8">
                    <img
                      className="rounded-full flex-shrink-0 mr-4"
                      src="https://res.cloudinary.com/dykrzfpex/image/upload/v1639531873/ehfb_ctdfth.jpg"
                      width="40"
                      height="40"
                      alt="Features avatar"
                    />
                    <div>
                      <blockquote className="text-gray-400 italic mb-3">
                        "Where there is a will, there is a way".
                      </blockquote>
                      <div className="text-gray-700 font-medium">
                        <cite className="text-gray-200 not-italic">
                          — Erick Hernandez
                        </cite>{" "}
                        -{" "}
                        <a
                          className="text-blue-700 hover:text-gray-200 transition duration-150 ease-in-out"
                          href="#0"
                        >
                          CEO
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesHome;
