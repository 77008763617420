import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from "../utils/Dropdown";

function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Cruip">
              <img
                src="https://res.cloudinary.com/dykrzfpex/image/upload/v1639548613/Engsys-Logo-new-small_xb36vw.jpg"
                width="200"
              />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:flex-grow">
            {/* Desktop menu links */}
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              {/* 1st level: hover */}
              <Dropdown title="Products">
                {/* 2nd level: hover */}
                <li>
                  <Link
                    to="/products/Reserv4me"
                    className="font-medium text-sm text-gray-400 hover:text-blue-600 flex py-2 px-4 leading-tight"
                  >
                    Reserv4me
                  </Link>
                  <Link
                    to="/products/Reserv4meCRM"
                    className="font-medium text-sm text-gray-400 hover:text-blue-600 flex py-2 px-4 leading-tight"
                  >
                    Reserv4me CRM
                  </Link>
                  {/*<Link
                    to="/products/kiosk"
                    className="font-medium text-sm text-gray-400 hover:text-blue-600 flex py-2 px-4 leading-tight"
                  >
                    Kiosk
                  </Link>
                  <Link
                    to="/contact"
                    className="font-medium text-sm text-gray-400 hover:text-blue-600 flex py-2 px-4 leading-tight"
                  >
                    Custom Websites
                  </Link>*/}
                </li>
              </Dropdown>
              <li>
                <Link
                  to="/about"
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Contact Us
                </Link>
              </li>
              {/* 1st level: hover */}
              {/*<Dropdown title="Support">*/}
              {/* 2nd level: hover */}
              {/*<li>
                  <Link
                    to="/contact"
                    className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight"
                  >
                    Contact us
                  </Link>
                </li>
                </Dropdown>*/}
            </ul>

            {/* Desktop sign in links */}
            {/*<ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <Link
                  to="/signin"
                  className="font-medium text-purple-600 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out"
                >
                  Sign in
                </Link>
              </li>
              <li>
                <Link
                  to="/signup"
                  className="btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3"
                >
                  Sign up
                </Link>
              </li>
  </ul>*/}
          </nav>

          {/* Mobile menu */}
          <div className="md:hidden">
            {/* Hamburger button */}
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && "active"}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <nav
              id="mobile-nav"
              ref={mobileNav}
              className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
              style={
                mobileNavOpen
                  ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 }
                  : { maxHeight: 0, opacity: 0.8 }
              }
            >
              <ul className="bg-gray-800 px-4 py-2">
                <li className="py-2 my-2 border-t border-b border-gray-700">
                  <span className="flex text-gray-300 py-2">Products</span>
                  <ul className="pl-4">
                    <li>
                      <Link
                        to="/products/Reserv4me"
                        className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
                      >
                        Reserv4me
                      </Link>
                      <Link
                        to="/products/Reserv4meCRM"
                        className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
                      >
                        Reserv4me CRM
                      </Link>
                      {/* <Link
                        to="/products/kiosk"
                        className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
                      >
                        Kiosk
                      </Link>
                      <Link
                        to="/contact"
                        className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
                      >
                        Custom Websites
                     </Link>*/}
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to="/about"
                    className="flex text-gray-300 hover:text-gray-200 py-2"
                  >
                    About us
                  </Link>
                </li>
                <li className="py-2 my-2 border-t border-b border-gray-700">
                  <span className="flex text-gray-300 py-2">Support</span>
                  <ul className="pl-4">
                    <li>
                      <Link
                        to="/contact"
                        className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
                      >
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </li>
                {/*<li>
                  <Link
                    to="/signin"
                    className="flex font-medium w-full text-purple-600 hover:text-gray-200 py-2 justify-center"
                  >
                    Sign in
                  </Link>
                </li>
                <li>
                  <Link
                    to="/signup"
                    className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out"
                  >
                    Sign up
                  </Link>
                </li>*/}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
