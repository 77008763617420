import React from "react";

import Header from "../../../partials/Header";
import PageIllustration from "../../../partials/PageIllustration";
import HeroFeatures from "./HeroFeatures";
import Stats from "./Stats";
import FeaturesZigzag from "./FeaturesZigzag";
import FeaturesBlocks from "./FeaturesBlocks";
import CaseStudies from "./CaseStudies";
import Cta from "./Cta";
import Footer from "../../../partials/Footer";

function Features() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <HeroFeatures />
        <Stats />
        <FeaturesZigzag />
        <FeaturesBlocks />
        <CaseStudies />
        <Cta />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Features;
