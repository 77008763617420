import React from "react";

import Header from "../../../partials/Header";
import PageIllustration from "../../../partials/PageIllustration";
import HeroFeatures from "./HeroFeatures";
import Stats from "./Stats";
import FeaturesZigzag from "./FeaturesZigzag";
import FeaturesBlocks from "./FeaturesBlocks";
import CaseStudies from "./CaseStudies";
import Cta from "./Cta";
import Footer from "../../../partials/Footer";

function Features() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}

        {/*  Page sections */}
        <HeroFeatures />
        {/*<Stats />*/}
        <FeaturesZigzag />
        <Cta />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Features;
