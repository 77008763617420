import React from "react";

function HeroFeatures() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        {/* Illustration behind hero content */}

        {/* Hero content */}
        <div className="relative pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Content */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 mb-4" data-aos="fade-up">
              Reserv4me
            </h1>
            <p
              className="text-xl text-gray-400"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Reserv4me is a booking engine that allows any property owner to
              let customers make reservation online at anytime, making
              transaction a breeze.
            </p>
          </div>
          {/* Image */}
          <div className="composition lg:h-112 md:h-96 h-40 mx-auto pb-12 md:pb-16">
            <img
              className="composition__photo composition__photo--p1"
              src="https://res.cloudinary.com/dykrzfpex/image/upload/v1645851472/Screenshot_from_2022-02-25_22-31-44_lvciyv.png"
              width="768"
              height="432"
              alt="Hero"
              data-aos="fade-up"
            />
            <img
              className="composition__photo composition__photo--p2"
              src="https://res.cloudinary.com/dykrzfpex/image/upload/v1645851391/Screenshot_from_2022-02-25_22-32-27_kwsayf.png"
              width="768"
              height="432"
              alt="Hero"
              data-aos="fade-up"
            />
            <img
              className="composition__photo composition__photo--p3"
              src="https://res.cloudinary.com/dykrzfpex/image/upload/v1645850982/Screenshot_from_2022-02-25_15-52-29_tqxgiw.png"
              width="768"
              height="432"
              alt="Hero"
              data-aos="fade-up"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroFeatures;
